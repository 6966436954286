import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, navigate } from 'gatsby'

// Models | Types
import { CharacterModel } from '../../../models'

// Config
import { bios } from '../../../config/localization'

// Templates
import PageTemplate from '../PageTemplate'
import WorldTemplate from '../WorldTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// GraphQL
import { getGraphChapters, getGraphCharacters } from '../../../graphQL'

// Utils
import { createPath, getFont } from '../../../utils/utils'

// Components
import DefaultHelmet from '../../atoms/DefaultHelmet'
import CharacterCard from '../../molecules/CharacterCard'

// Style
import {
  characterHr,
  characterImg,
  characterTown,
  characterTownIcon,
  characterLink,
  characterLeft,
  characterStat,
  characterRight,
  characterLabel,
  characterStats,
  characterYellow,
  characterWrapper,
  characterReleated,
  characterDescription,
} from './styles.module.scss'

const CharacterTemplate: React.FC<PageProps<any, CharacterModel>> = ({
  pageContext: {
    name,
    food,
    race,
    town,
    height,
    weight,
    epithet,
    birthday,
    description,
    firstChapter,
    relatedCharacters,
  },
}) => {
  const { language } = useContext(LocalizationContext)

  const allChapters = getGraphChapters()
  const allCharacters = getGraphCharacters()

  const related = relatedCharacters.map((character) =>
    allCharacters.find((related) => Number(related.id) === character)
  )
  const chapter = allChapters.find((ch) => Number(ch.id) === firstChapter)

  const [localization, setLocalization] = useState(bios.Default)
  const [units, setUnits] = useState<'metric' | 'imperial'>('metric')

  useEffect(() => {
    const newLocalization = bios[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Bio' : 'Biografía'}
          {`: ${name}`}
        </title>
        <meta
          name="description"
          content={description ? description[language] : ''}
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? `Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, biografía, ${name}`
              : `Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, bio, ${name}`
          }
        />
        <DefaultHelmet />
      </Helmet>
      <WorldTemplate location="bios" title={name}>
        <div className={characterWrapper}>
          <div className={characterLeft}>
            <div className={characterStats}>
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.birthday}
              </h3>
              <p className={`${characterStat} ${getFont('font-lato')}`}>
                {birthday[language]}
              </p>
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.weight}
              </h3>
              <p className={`${characterStat} ${getFont('font-lato')}`}>
                {units === 'metric'
                  ? `${weight} kg `
                  : `${Math.floor(weight * 2.20462)} lb `}
                <span
                  onClick={() => {
                    if (units === 'metric') setUnits('imperial')
                    else setUnits('metric')
                  }}
                  style={{ cursor: 'pointer' }}
                >
                  *
                </span>
              </p>
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.height}
              </h3>
              <p className={`${characterStat} ${getFont('font-lato')}`}>
                {units === 'metric'
                  ? `${height} m `
                  : `${Math.floor(height * 3.28084)}'${Math.floor(
                      (height * 39.3701) % 12
                    )}'' `}
                <span
                  onClick={() => {
                    if (units === 'metric') setUnits('imperial')
                    else setUnits('metric')
                  }}
                >
                  *
                </span>
              </p>
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.species}
              </h3>
              {race[language].split('\r\n').map((rac) => (
                <p
                  key={`species_${rac.split('(')[0]}`}
                  className={`${characterStat} ${getFont('font-lato')}`}
                >
                  {rac}
                </p>
              ))}
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.food}
              </h3>
              <p className={`${characterStat} ${getFont('font-lato')}`}>
                {food[language]}
              </p>
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.town}
              </h3>
              <p
                className={`${characterStat} ${characterTown} ${
                  town.name !== 'Unknown' ? characterLink : ''
                } ${getFont('font-lato')}`}
                onClick={() => {
                  if (town.name !== 'Unknown')
                    navigate(`/world/map/${createPath(town.name)}`)
                }}
              >
                <img
                  className={characterTownIcon}
                  src={`https://dilancovak.com/.img/emblems/${createPath(
                    town.name
                  )}.png`}
                />
                {town.name}
              </p>
              <h3 className={`${characterLabel} ${getFont('font-patua')}`}>
                {localization.chapter}
              </h3>
              <p
                className={`${characterStat} ${characterLink} ${getFont(
                  'font-lato'
                )}`}
                onClick={() => {
                  navigate(
                    `/reader/${chapter?.series.prefix}${chapter?.number}`
                  )
                }}
              >
                {`${chapter?.series.prefix}${chapter?.number} - ${chapter?.title[language]}`}
              </p>
            </div>
            <img
              className={characterImg}
              src={`https://dilancovak.com/.img/bios/${createPath(name)}.png`}
            />
          </div>
          <div className={characterRight}>
            <div className={`${characterLabel} ${getFont('font-patua')}`}>
              {epithet[language]}
            </div>
            <hr className={characterHr} />
            <div className={`${characterDescription} ${getFont('font-lato')}`}>
              {description[language]}
            </div>
            <h3
              className={`${characterLabel} ${characterYellow} ${getFont(
                'font-patua'
              )}`}
            >
              {localization.related}
            </h3>
            <div className={characterReleated}>
              {related.map((rel) => (
                <div key={`related_${rel?.name}`}>
                  <CharacterCard
                    town={rel?.town.name || ''}
                    name={rel?.name || ''}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </WorldTemplate>
    </PageTemplate>
  )
}

export default CharacterTemplate
